import React from 'react';
import Page from '../components/Page/Page';
import Border from '../components/Border/Border';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import Text from '../components/Text/Text';
import Headshot from '../components/Headshot/Headshot';

interface TeamCard {
    name: string;
    role: string;
    bio: React.ReactElement<any>;
    image: string;
}

const teamCards: TeamCard[] = [
    {
        name: `Andrew (Andy) Valenti`,
        role: `Founder & CEO`,
        bio: <p>
            Andy is a seasoned technical product manager and research scientist with over 25 years of experience advancing artificial intelligence technologies across diverse domains such as natural language processing, speech recognition, and human-machine interaction. His extensive background includes leading large-scale projects in both commercial and defense sectors, consistently delivering innovative solutions that enhance user interaction and business processes.
        </p>,
        image: `/images/team/AValenti-HeadShot.png`,
    },
    // {
    //     name: `Gustavo Aceves`,
    //     role: `Global Development Lead`,
    //     bio: <p>
    //         Gustavo has over 20 years of experience as strategic counsel, helping global companies across the Americas and Europe navigate technology complexities to drive business growth while mitigating regulatory risks. His forward-looking approach delivers tailored strategies for success in the dynamic AI landscape.
    //     </p>,
    //     image: `/images/team/gustavo.jpg`,
    // },
    {
        name: `Sanchit Goel`,
        role: `AI Research Intern`,
        bio: <p>
            Sanchit is pursuing a Master of Science in Data Science at the University of California, San Diego. As a Machine Learning Researcher at UC San Diego, he applies mixed-effects models and causal inference techniques to study biomarker trajectories in schizophrenia patients while developing multi-modal models for detecting social isolation in older adults. His expertise in natural language processing, deep learning, and large language models improved model performance and reduced processing times while implementing Explainable AI to detect model drift. Sanchit excels in leading cross-functional teams, delivering innovative AI solutions, and driving impactful market strategies.
        </p>,
        image: `/images/team/Sanchit_circ_headshot.png`,
    },
    {
        name: `Michael Gold`,
        role: `Full-Stack Lead Developer`,
        bio: <p>
            Michael is a Boston-based software engineer specializing in creating robust machine learning pipelines for healthcare applications. He has previously worked in developing Electronic Health Records (EHR) systems and sentiment analysis technology for Parkinson's patients. With a strong commitment to software engineering principles, he ensures that all code is well-architected and designed for sustainability and efficiency. Michael is also an avid pianist and acrobat.
        </p>,
        image: `/images/team/MGold-photo_for_resume_small.png`,
    },
    {
        name: `Damien Martin`,
        role: `Senior Machine Learning Engineer`,
        bio: <p>
            Damien brings a wealth of electrical engineering and computer science expertise. With a Master's and Bachelor's from MIT, he leads technical teams on SBIR research projects, focusing on developing innovative solutions such as deep graph neural networks and computer vision pipelines. His previous experience includes designing predictive models for automated fault detection in manufacturing, optimizing cooling systems in the Philippines, and creating swarm robotics controllers. Passionate about advanced machine learning techniques, Damien consistently delivers cutting-edge solutions for real-world challenges at Spark Interventions.
        </p>,
        image: `/images/team/DamienWMartin_circ_HeadShot.png`,
    },
    {
        name: `Quinn Pham`,
        role: `Research Intern`,
        bio: <p>
            Quinn is a PhD student in developmental cognitive neuroscience at the University of Massachusetts Boston, slated to graduate in 2026. She holds a B.A. in Cognitive Science from Tufts University and an M.A. in Psychology from Boston University. She has experience in human robot interaction, visualization, and psychology research with both adults and children. She contributes a cognitive science perspective to the team and enjoys working on simplifying statistics and research for a general audience.
        </p>,
        image: `images/team/QuinnPhamHeadshot.png`,
    },
    {
        name: `Alessandra Valenti`,
        role: `Business Strategy Practice Director`,
        bio: <div>
            <p>
                Alessandra Valenti is a seasoned business development leader and strategic consultant with
                extensive global market experience. As Director of Business Development for Latin America at
                frog Design in Mexico City, she established frog's Mexico office, drove new business
                opportunities, and managed major client accounts until early 2024. Previously, as Associate
                Strategy Director at frog Design in New York, she led interdisciplinary teams in customer
                experience, new venture design, and business model creation. In early 2024, Alessandra left
                frog Design to launch Manná, a wholesome food concept in Mexico City.
            </p>
            <p>
                Her early career included finance and performance management consulting at Accenture and
                working as a Debt Capital Markets Analyst at Citigroup Global Capital Markets. Alessandra
                holds a Master of Arts in Law and Diplomacy from The Fletcher School at Tufts University and a
                Bachelor of Science in Business Administration from the University of Richmond. She is fluent in
                English, Spanish, and French, with conversational skills in Portuguese.
            </p>
        </div>,
        image: `/images/team/alessandra.jpeg`,
    },
];

const Team: React.FC = () => {
    return (
        <div style={{color: `#3c4f73`}}>
        <Page elements={[
            <Text texts={[
                <h1>Our Team</h1>,
                <p>Spark Interventions brings together a team with decades of collective experience in AI development, strategic consulting, and software engineering.</p>,
            ]}/>,
            ...teamCards.map(({ name, role, bio, image }) => (
                <Border element={
                    <TwoColumns
                        leftAboveInMobile={true}
                        left={
                            <Headshot
                                src={image}
                                alt={name}
                            />
                        }
                        right={
                            <Text texts={[
                                <h4 style={{ fontSize: `20pt`, color: `#e08132` }}>{name}</h4>,
                                <h4>{role}</h4>,
                                bio,
                            ]}/>
                        }
                    />
                }/>
            )),
        ]}/>
        </div>
    );
};

export default Team;
